import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  overflow: scroll;
  width: 100%;
  margin-top: 40px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  min-width: 500px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;

  thead {
    background-color: ${$.color.blue3};
    color: ${$.color.white};
    text-align: center;

    tr {
      td {
        padding: 10px 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #feecde;

        td {
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }

      td {
        padding: 10px 15px;
        font-size: 15px;
        &:last-child {
          text-align: center;
        }
        &:nth-child(2),
        &:nth-child(1) {
          text-align: center;
        }
      }
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    thead {
      tr {
        td {
          padding: 10px 20px;
          font-size: 15px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 20px;
          font-size: 15px;
        }
      }
    }
  }
  // #endregion
`;

const OnlinePill = styled.div`
  background-color: rgba(4, 123, 67, 0.2);
  font-size: 14px;
  color: rgb(4, 123, 67);
  padding: 5px 10px;
  border-radius: 15px;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: bold;
  max-width: 80px;
  margin: auto;
`;

const OfflinePill = styled(OnlinePill)`
  background-color: rgba(201, 29, 29, 0.2);
  color: #c91d1d;
`;

const ExtraHoursTable = ({ data }) => (
  <Container>
    <Table>
      <thead>
        <tr>
          <td>Date</td>
          <td>Extra Hours Logged</td>
          <td>Session Type</td>
        </tr>
      </thead>
      <tbody>
        {data.map(({ date_id: did, hours, class_status: cs }) => {
          const parsedDate = () => {
            const arr = did.split('_');

            if (arr[3]) {
              return `${arr[1]} ${arr[0]} '${arr[2].substr(
                2,
                4
              )} (Extra Session)`;
            }

            return `${arr[1]} ${arr[0]} '${arr[2].substr(2, 4)}`;
          };
          return (
            <tr>
              <td>{parsedDate()}</td>
              <td>{hours > 0 ? `+${hours}` : hours}</td>
              <td>
                {cs === 'offline' ? (
                  <OfflinePill>Offline</OfflinePill>
                ) : (
                  <OnlinePill>Online</OnlinePill>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </Container>
);

ExtraHoursTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date_id: PropTypes.string,
      hours: PropTypes.number,
    })
  ),
};

ExtraHoursTable.defaultProps = {
  data: [],
};

export default ExtraHoursTable;
