import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';

import $ from '../../../styles/global';
import { getAttendanceReportGQL } from '../../../apollo/queries';
import Stats from './Stats';
import AttendanceTable from './AttendanceTable';
import ExtraHoursTable from './ExtraHoursTable';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const Title = styled.div`
  font-size: 24px;
  margin-bottom: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  & > div {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    flex-direction: column;
    & > div {
      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
  // #endregion
`;

const AttendanceReport = () => {
  const [attendance, setAttendance] = useState({});
  const [totalHours, setTotalHours] = useState({
    total: 0,
    attended: 0,
  });
  const [getAttendanceReport] = useLazyQuery(getAttendanceReportGQL, {
    onCompleted: ({ getAttendanceReport: data }) => {
      setAttendance(data);

      // 110 hours is the minimum hours they need to take
      // the stats are only there to show the numbers, not to do any hidden penalize
      setTotalHours(() => {
        const additionalHours =
          data.hours.reduce((current, { hours: next }) => current + next, 0) ||
          0;
        const totalTaken =
          data.attendance.reduce(
            (hours, { hours: nextSum }) => nextSum + hours,
            0
          ) + additionalHours;

        return {
          total: 110,
          totalTaken,
        };
      });
    },
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(({ username }) => {
      getAttendanceReport({ variables: { student_id: username } });
    });
  }, []);

  return (
    <Section>
      <Container>
        <Title>Attendance Report</Title>
        <StatsContainer>
          <Stats
            title="Total Sessions Taken"
            numerator={totalHours.totalTaken}
            denominator={totalHours.total}
          />
        </StatsContainer>
        <AttendanceTable attendance={attendance.attendance} />
        {attendance.hours && attendance.hours.length > 0 && (
          <ExtraHoursTable data={attendance.hours} />
        )}
      </Container>
    </Section>
  );
};

export default AttendanceReport;
