import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';

import Utils from '../utils';
import AttendanceReport from '../components/student/AttendanceReport';
import Seo from '../components/Seo';

const Report = () => {
  const [group, setGroup] = useState(null);

  useEffect(() => {
    Utils.getGroupName().then((res) => {
      setGroup(res);
    });
  }, []);

  useEffect(() => {
    switch (group) {
      case Utils.ADMIN_GROUP:
      case Utils.INSTRUCTORS_GROUP:
        navigate('/');
        break;
      default:
        break;
    }
  }, [group]);

  switch (group) {
    case Utils.STUDENTS_GROUP:
      return (
        <>
          <Seo title="SGCC IMDA Attendance | Report" />
          <AttendanceReport />
        </>
      );
    case Utils.ADMIN_GROUP:
    case Utils.INSTRUCTORS_GROUP:
    case null:
    default:
      return <div />;
  }
};

export default Report;
