import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  position: relative;
  display: inline-block;
  border: 1px solid ${$.color.gray};
  border-radius: 10px;
  max-width: 300px;
  text-align: center;
  width: 100%;
`;

const Title = styled.div`
  position: absolute;
  top: -8px;
  left: 10px;
  color: ${$.color.gray};
  background-color: ${$.color.white};
  padding: 0 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    font-size: 13px;
  }
  // #endregion
`;

const Division = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    &:first-child {
      font-size: 60px;
      line-height: 1em;
      margin-right: ${$.layout().margin5}px;
    }
    &:last-child {
      font-size: 16px;
      align-self: flex-end;
      position: relative;
      top: -5px;
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    padding: 30px 10px 20px 10px;

    & > div {
      &:first-child {
        font-size: 40px;
        line-height: 1em;
        margin-right: ${$.layout().margin5}px;
      }
      &:last-child {
        font-size: 14px;
        align-self: flex-end;
        position: relative;
        top: -5px;
      }
    }
  }
  // #endregion
`;

const Stats = ({ title, numerator, denominator }) => (
  <Container>
    <Title>{title}</Title>
    <Division>
      <div>{`${numerator}/${denominator}`}</div>
      <div>hours</div>
    </Division>
  </Container>
);

Stats.defaultProps = {
  title: '',
  numerator: 1,
  denominator: 1,
};

Stats.propTypes = {
  title: PropTypes.string,
  numerator: PropTypes.number,
  denominator: PropTypes.number,
};

export default Stats;
