import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Utils from '../../../utils';

const Container = styled.div`
  overflow: scroll;
  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: none;
  min-width: 500px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;

  thead {
    background-color: ${$.color.orange4};
    color: ${$.color.white};
    text-align: center;

    tr {
      td {
        padding: 10px 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #feecde;

        td {
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
      }

      td {
        padding: 10px 15px;
        font-size: 15px;
        &:last-child {
          text-align: center;
        }
        &:nth-child(3),
        &:nth-child(1) {
          text-align: center;
        }
      }
    }
  }

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    thead {
      tr {
        td {
          padding: 10px 20px;
          font-size: 15px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 20px;
          font-size: 15px;
        }
      }
    }
  }
  // #endregion
`;

const OnlinePill = styled.div`
  background-color: rgba(4, 123, 67, 0.2);
  font-size: 14px;
  color: rgb(4, 123, 67);
  padding: 5px 10px;
  border-radius: 15px;
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: bold;
  max-width: 80px;
  margin: auto;
`;

const OfflinePill = styled(OnlinePill)`
  background-color: rgba(201, 29, 29, 0.2);
  color: #c91d1d;
`;

const AttendanceTable = ({ attendance }) => (
  <Container>
    <Table>
      <thead>
        <tr>
          <td>Attended</td>
          <td>Timeslot</td>
          <td>Hours</td>
          <td>Session Type</td>
        </tr>
      </thead>
      <tbody>
        {attendance?.map(
          ({ class_status: s, time_slot_taken: t, date_id: d, hours }) => {
            const attended = d.split('_');

            return (
              <tr key={d}>
                <td>{`${attended[1]} ${attended[0]} '${attended[2].substr(
                  2,
                  4
                )}`}</td>
                <td>{t}</td>
                <td>{hours}</td>
                <td>
                  {s === Utils.OFFLINE ? (
                    <OfflinePill>Offline</OfflinePill>
                  ) : (
                    <OnlinePill>Online</OnlinePill>
                  )}
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </Table>
  </Container>
);

AttendanceTable.defaultProps = {
  attendance: [],
};

AttendanceTable.propTypes = {
  attendance: PropTypes.arrayOf(
    PropTypes.shape({
      class_status: PropTypes.string,
      time_slot_taken: PropTypes.string,
      hours: PropTypes.number,
      date_id: PropTypes.string,
    })
  ),
};

export default AttendanceTable;
